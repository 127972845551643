import * as React from 'react';
import cn from 'classnames';
import googlePlay from 'images/googlePlay.png';
import appStore from 'images/appStore.png';

type Props = {
  readonly className?: string;
};

const linkCn = 'transition-opacity duration-300 ease-in-out hover:opacity-70';

export const StoreLinks = ({ className }: Props) => (
  <div className={cn('flex items-center', className)}>
    <a
      href="https://play.google.com/store/apps/details?id=com.familyfriend.client"
      target="_blank"
      rel="noopener noreferrer"
      className={cn(linkCn, 'mr-3')}
    >
      <img src={googlePlay} alt="" />
    </a>
    <a href="https://itunes.apple.com/ru/app/id1121591818" target="_blank" rel="noopener noreferrer" className={linkCn}>
      <img src={appStore} alt="" />
    </a>
  </div>
);
