import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import format from 'date-fns/format';
import locale from 'date-fns/locale/ru';
import parseISO from 'date-fns/parseISO';
import { UpdatedAtQuery } from 'generated/graphql';

export const UpdatedAt = () => {
  const data = useStaticQuery<UpdatedAtQuery>(graphql`
    query UpdatedAtQuery {
      now
    }
  `);

  return <span>Последнее обновление: {format(parseISO(data.now as string), 'd MMMM HH:mm', { locale })}</span>;
};
