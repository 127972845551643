import * as React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import { clickSocial } from 'analytics';
import { UpdatedAt } from './UpdatedAt';
import { Rating } from './Rating';
import { StoreLinks } from './StoreLinks';
import { ModalButton } from './ModalLogic';
import { Icon } from './icons';

const socialLinkCn = 'p-2.5 transition-opacity duration-300 ease-in-out hover:opacity-70 lg:px-3.5';

type Props = {
  className?: string;
};

export const Footer = ({ className }: Props) => (
  <footer className={cn(className, 'bg-red-100 py-10 lg:py-7 px-2')}>
    <div className="container flex items-center justify-between mb-8 lg:flex-col-reverse lg:mb-0">
      <div className="lg:text-center">
        <Link
          to="/agreement"
          className="block text-gray-200 transition-colors duration-300 ease-in-out hover:text-orange-50 mb-5 lg:text-sm lg:mb-2"
        >
          Пользовательское соглашение <br /> и политика обработки персональных данных
        </Link>
        <div className="text-gray-200 lg:text-sm">
          Рейтинг <span className="text-yellow-50 font-medium">4.9</span> <Rating rating={4.9} /> на основании{' '}
          <a
            href="https://goo.gl/maps/nkKrN7h4dmSYV3C1A"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-200 border-b border-current pb-px transition-colors duration-300 ease-in-out hover:border-transparent"
          >
            отзывов
          </a>{' '}
          24 клиентов
        </div>
      </div>
      <div className="flex flex-col items-end lg:items-center">
        <ModalButton
          options={{
            type: 'support',
          }}
          className="flex items-center h-11 text-orange-50 text-sm font-medium uppercase tracking-wider border-2 border-orange-50 rounded-md px-4 transition-colors duration-300 ease-in-out hover:text-white hover:bg-orange-200 hover:border-orange-200 mb-6"
        >
          Связаться с поддержкой
        </ModalButton>
        <StoreLinks className="mb-3.5" />
        <div className="flex items-center -mr-2.5 lg:mb-3.5">
          <a
            href="https://www.facebook.com/familyfriendmoscow/"
            target="_blank"
            rel="noopener noreferrer"
            className={socialLinkCn}
            onClick={() => clickSocial('facebook')}
          >
            <Icon name="facebook" />
          </a>
          <a
            href="https://www.instagram.com/familyfriend_moscow/"
            target="_blank"
            rel="noopener noreferrer"
            className={socialLinkCn}
            onClick={() => clickSocial('instagram')}
          >
            <Icon name="instagram" />
          </a>
          <a
            href="https://vk.com/familyfriend_moscow"
            target="_blank"
            rel="noopener noreferrer"
            className={socialLinkCn}
            onClick={() => clickSocial('vkontakte')}
          >
            <Icon name="vk" />
          </a>
          <a
            href="https://twitter.com/share?url=https://familyfriend.com&text=FamilyFriend Быстрая доставка свежих и вкусных продуктов"
            target="_blank"
            rel="noopener noreferrer"
            className={socialLinkCn}
            onClick={() => clickSocial('twitter')}
          >
            <Icon name="twitter" />
          </a>
        </div>
      </div>
    </div>
    <div className="container text-cyan-200 text-center lg:hidden">
      <div>© FamilyFriend, 2015 - {new Date().getFullYear()}</div>
      <UpdatedAt />
    </div>
  </footer>
);
