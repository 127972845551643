import * as React from 'react';

import { HomeHeader } from './HomeHeader';
import { ModalLogic } from './ModalLogic';
import { HeadMetaData } from './HeadMetaData';
import { Chat } from './Chat';

type Props = {
  readonly children: React.ReactNode;
  readonly raiseChatOnMobile?: boolean;
};

export const MainLayout = ({ children, raiseChatOnMobile }: Props) => (
  <>
    <div className="flex flex-col min-h-screen">
      <HeadMetaData />
      <HomeHeader />
      {children}
    </div>
    <ModalLogic />
    <Chat raiseChat={raiseChatOnMobile} />
  </>
);
