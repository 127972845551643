import * as React from 'react';
import { MainLayout } from 'components/MainLayout';
import { Footer } from 'components/Footer';

const ReturnsPage = () => (
  <MainLayout>
    <div className="container my-10 sm:my-0 px-2">
      <p className="text-[40px] py-10">Возврат</p>
      <div>
        <div className="mb-[30px] flex items-start">
          <div className="bg-orange-50 rounded-full flex-none mr-[10px] mt-[9px] w-[10px] h-[10px]" />
          <p className="text-lg">
            Вы можете проверить заказ в присутствии курьера и отказаться от позиций, которые вас не устроят.
          </p>
        </div>

        <div className="mb-[30px] flex items-start">
          <div className="bg-orange-50 rounded-full flex-none mr-[10px] mt-[9px] w-[10px] h-[10px]" />
          <p className="text-lg">
            Если вы обнаружили, что вам что-то не нравится после ухода курьера, сообщите нам по телефону или электронной
            почте. Достаточно сообщить номер заказа и название позиций, от которых вы желаете отказаться. Мы обменяем
            продукты или вернём деньги.
          </p>
        </div>

        <div className="mb-[30px] flex items-start">
          <div className="bg-orange-50 rounded-full flex-none mr-[10px] mt-[9px] w-[10px] h-[10px]" />
          <p className="text-lg">
            При оплате заказа банковской картой возврат средств производится в течение пяти рабочих дней на ту же
            банковскую карту, с которой была произведена оплата.
          </p>
        </div>

        <div className="mb-[30px] flex items-start">
          <div className="bg-orange-50 rounded-full flex-none mr-[10px] mt-[9px] w-[10px] h-[10px]" />
          <p className="text-lg">
            В случае оплаты наличными денежные средства будут возвращены путём безналичного перевода на банковский счёт
            клиента в течение пяти рабочих дней.
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </MainLayout>
);

export default ReturnsPage;
