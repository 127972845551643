import { createElement, HTMLAttributes } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getProps } from './getProps';

interface Props extends HTMLAttributes<HTMLDivElement> {
  name: string;
  size?: number;
}

export const Icon = ({ name, size, ...props }: Props) => {
  const data = useStaticQuery(graphql`
    {
      allFile {
        nodes {
          name
          publicURL
        }
      }
    }
  `);

  const widthHeight = size ? { width: `${size}px`, height: `${size}px` } : {};

  // @ts-ignore
  const file = data.allFile.nodes.find((n) => n.name === name);
  const style = { backgroundImage: `url(${file.publicURL})`, ...widthHeight };

  return createElement('i', getProps('', { ...props, style }));
};
